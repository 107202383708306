/**
 * Config Imports
*/

import { TwitchConfig } from '@config/Twitch';

/**
 * System Imports
*/

import { CommandRegister } from '@system/Command';
import { ConfigInit } from '@system/Config';
import { TmiInit } from '@system/Tmi';

/**
 * Sibling Imports
*/

import { JukeboxInit, JukeboxReportSongInfo } from './Jukebox';

/**
 * Functions
*/

/**
 * @return {void}
 */
function ApplicationRegister(): void
{
    CommandRegister('song', JukeboxReportSongInfo);
    CommandRegister('currentsong', JukeboxReportSongInfo);
}

/**
 * @return {Promise<void>}
 */
async function ApplicationInit(): Promise<void>
{
    await ConfigInit();
    await JukeboxInit();
    await TmiInit(TwitchConfig.username, TwitchConfig.oauth, TwitchConfig.channel);
}

/**
 * Main
*/

ApplicationRegister();
ApplicationInit();
