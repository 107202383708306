/**
 * Root Imports
*/

import { JukeboxConfig } from '@config/Jukebox';
import { TmiSend } from '@system/Tmi';
import { User } from '@system/User';
import { RandomFrom, RandomInt } from '@system/Utility';

/**
 * Sibling Imports
*/

import { CurrentSong, Group, Song, SongRequest } from './types';

/**
 * Locals
*/

let _requests: Array<SongRequest>;
let _groups: Record<string, Group>;
let _groupUpcoming: Array<number>;
let _groupHistory: Array<number>;
let _songHistory: Array<number>;
let _audio: HTMLAudioElement;
let _volumeDefault: number = 1.0;
let _announced: boolean = false;
let _announceEnabled: boolean = false;
let _current: CurrentSong;

/**
 * Private Functions
*/

/**
 * @return {Group}
 */
function _getGroup(uid: number): Group
{
    for (const key in _groups) {
        if (_groups[key].uid === uid) {
            return _groups[key];
        }
    }

    return null;
}

/**
 * Return a random group that has at least one song that has not been recently played.
 *
 * @return {Group}
 */
function _getRandomGroup(): Group
{
    const uid = RandomFrom(_groupUpcoming);
    const group = _getGroup(uid);

    for (const song of group.songs) {
        if (_songHistory.indexOf(song.uid) === -1) {
            return group;
        }
    }

    return _getRandomGroup();
}

/**
 * Return a random song from the given group.
 *
 * @return {string}
 */
function _getRandomSong(group: string | Group, attempts: number = 0): Song
{
    const song = typeof group === 'string' ? RandomFrom(_groups[group].songs) : RandomFrom(group.songs);

    if (_songHistory.indexOf(song.uid) !== -1 && attempts < JukeboxConfig.songs.shuffle.attempts) {
        return _getRandomSong(group, attempts + 1);
    }

    return song;
}

/**
 * @return {void}
 */
function _trackHistory(group: Group, song: Song): void
{
    _songHistory.push(song.uid);

    if (_songHistory.length === JukeboxConfig.songs.history.count) {
        _songHistory.shift();
    }

    _groupHistory.push(group.uid);
    _groupUpcoming.splice(_groupUpcoming.indexOf(group.uid), 1);

    const limit = RandomInt(3, 6);

    while (_groupHistory.length > limit) {
        _groupUpcoming.push(_groupHistory.shift());
    }
}

/**
 * @return {string}
 */
function _getRandomSongUri(): string
{
    const group = _getRandomGroup();
    const song = _getRandomSong(group);

    _trackHistory(group, song);

    _current = {
        game: group.title,
        platform: group.platform,
        title: song.title,
        volume: group.volume || song.volume || _volumeDefault,
    };

    return `${ JukeboxConfig.uri.prefix }/${ group.key }/${ song.filename }`;
}

/**
 * @return {void}
 */
function _handleLoaded(): void
{
    _audio.play();
}

/**
 * @return {void}
 */
function _handlePlayStart(): void
{
    if (!_announced) {
        _announced = true;
        if (_announceEnabled) {
            TmiSend(`Jukebox is now playing ${ _current.game } - ${ _current.title } for the ${ _current.platform }`);
        }
    }
}

/**
 * @return {void}
 */
function _handlePlayEnd(): void
{
    _audio = null;
    _current = null;
    _announced = false;

    JukeboxPlayNewSong();
}

/**
 * @return {void}
 */
function _handlePlaybackToggle(): void
{
    if (_audio) {
        if (_audio.paused) {
            _audio.play();
        } else {
            _audio.pause();
        }
    }
}

/**
 * @return {void}
 */
function _handleVolumeChange(event: Event): void
{
    _volumeDefault = parseFloat((event.target as HTMLInputElement).value);

    if (_audio) {
        _audio.volume = _volumeDefault;
    }
}

/**
 * @return {void}
 */
function _handleRequestPlatform(requester: User, platform: string): void
{
    const available: Array<Group> = [];

    for (const key in _groups) {
        if (_groups[key].platform === platform && _groupUpcoming.indexOf(_groups[key].uid) !== -1) {
            available.push(_groups[key]);
        }
    }

    if (available.length === 0) {
        // error
    }

    const group = RandomFrom(available);
    const song = _getRandomSong(group);

    _trackHistory(group, song);
    _requests.push({
        requester,
        group,
        song,
    });
}

/**
 * Public Functions
*/

/**
 * @return {void}
 */
export function JukeboxRequest(user: User, query: string): void
{
    switch (query = query.toLowerCase()) {
    case 'nes':
    case 'nintendo':
        return _handleRequestPlatform(user, 'NES');
    case 'snes':
    case 'supernintendo':
    case 'super nintendo':
        return _handleRequestPlatform(user, 'SNES');
    case 'n64':
    case 'nintendo64':
    case 'nintendo 64':
        return _handleRequestPlatform(user, 'Nintendo 64');
    case 'ds':
    case 'nds':
    case 'nintendods':
    case 'nintendo ds':
        return _handleRequestPlatform(user, 'Nintendo DS');
    case 'genesis':
    case 'segagenesis':
    case 'sega genesis':
        return _handleRequestPlatform(user, 'Sega Genesis');
    case 'ps':
    case 'psx':
    case 'ps1':
    case 'playstation':
    case 'play station':
        return _handleRequestPlatform(user, 'Playstation');
    }
}

/**
 * @return {void}
 */
export function JukeboxReportSongInfo(): void
{
    if (!_current) {
        TmiSend('Jukebox is not playing.');
    } else {
        TmiSend(`Jukebox is playing ${ _current.game } - ${ _current.title } for the ${ _current.platform }`);
    }
}

/**
 * @return {void}
 */
export function JukeboxPlayNewSong(): void
{
    if (_audio) {
        _audio.pause();
        _announced = false;
    }

    _announceEnabled = !!(document.getElementById('SongAnnounce') as HTMLInputElement).checked;

    _audio = new Audio(_getRandomSongUri());
    _audio.volume = _current.volume;

    _audio.addEventListener('loadeddata', _handleLoaded);
    _audio.addEventListener('play', _handlePlayStart);
    _audio.addEventListener('ended', _handlePlayEnd);

    document.getElementById('SongGame').innerHTML = _current.game;
    document.getElementById('SongPlatform').innerHTML = _current.platform;
    document.getElementById('SongTitle').innerHTML = _current.title;
    document.getElementById('SongVolume').innerHTML = _current.volume.toFixed(2);
}

/**
 * @return {Promise<void>}
 */
export function JukeboxReload(): Promise<void>
{
    return new Promise(async resolve => {
        const response = await fetch('/json/jukebox.json');

        _groups = await response.json();
        _groupUpcoming = [];
        _groupHistory = [];
        _songHistory = [];

        for (const key in _groups) {
            _groupUpcoming.push(_groups[key].uid);
        }

        resolve();
    });
}

/**
 * @return {Promise<void>}
 */
export function JukeboxInit(): Promise<void>
{
    document.getElementById('SongVolumeInput').addEventListener('change', _handleVolumeChange);
    document.getElementById('SongPlaybackToggle').addEventListener('click', _handlePlaybackToggle);
    document.getElementById('SongReload').addEventListener('click', JukeboxReload);
    document.getElementById('SongNext').addEventListener('click', JukeboxPlayNewSong);

    return new Promise(async resolve => {
        _requests = [];
        await JukeboxReload();
        resolve();
    });
}
