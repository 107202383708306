/**
 * Global Imports
*/

import { Client, ChatUserstate, Options } from 'tmi.js';

/**
 * Config
*/

import { AppConfig } from '@config/App';
import { TwitchConfig } from '@config/Twitch';

/**
 * System
*/

import { ChatDispatch, ChatMessageCreate } from '@system/Chat';
import { Endpoint } from '@system/Network';
import { UserCreate } from '@system/User';
import { ChatCommandCreate, CommandDispatch } from '@system/Command';

/**
 * Locals
*/

let _client: Client;

/**
 * Private Functions
*/

/**
 * @param {string} channel
 * @param {ChatUserstate} state
 * @param {string} contents
 * @param {boolean} self
 *
 * @return {void}
 */
function _handleTmiChatMessage(
    channel: string,
    state: ChatUserstate,
    contents: string,
    self: boolean): void
{
    if (contents[0] === '!') {
        CommandDispatch(
            ChatCommandCreate(contents.slice(1), UserCreate(state), state, contents.slice(contents.indexOf(' ') + 1), self, channel)
        );
    } else {
        ChatDispatch(
            ChatMessageCreate(UserCreate(state), state, contents, self, channel)
        );
    }
}

/**
 * Public Functions
*/

/**
 * Writes a message to Twitch chat.
 *
 * @param {string} contents
 * @param {string?} channel
 *
 * @return {void}
 */
export function TmiSend(
    contents: string,
    channel?: string): void
{
    _client.say(channel || TwitchConfig.channel, contents);
}

/**
 * @param {string} username
 * @param {string} password
 * @param {string | Array<string>} channel
 *
 * @return {Promise<Endpoint>}
 */
export function TmiInit(
    username: string,
    password: string,
    channel: string | Array<string>): Promise<Endpoint>
{
    return new Promise((resolve, reject): void => {
        const options: Options = {
            options:    { debug: AppConfig.debug },
            connection: { secure: true },
            identity:   { username, password },
            channels:   Array.isArray(channel) ? channel : [ channel ],
        };

        try {
            _client = new Client(options);
        } catch (error) {
            return reject(error);
        }

        _client.on('message', _handleTmiChatMessage);
        _client.on('connected', (address: string, port: number): void => {
            resolve({ address, port });
        });

        _client.connect();
    });
}
