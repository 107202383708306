/**
 * Global Imports
*/

import { ChatUserstate } from 'tmi.js';

/**
 * Local Imports
*/

import { User } from '@system/User';

/**
 * Relative Imports
*/

import { ChatMessage, ChatEventHandler } from './types';

/**
 * Locals
*/

let _events: Array<ChatEventHandler> = [];

/**
 * Public Functions
*/

/**
 * @param {User} user
 * @param {ChatUserstate} state
 * @param {string} contents
 * @param {boolean} isBot
 * @param {string} channel
 *
 * @return {ChatMessage}
 */
export function ChatMessageCreate(
    user: User,
    state: ChatUserstate,
    contents: string,
    isBot: boolean,
    channel?: string): ChatMessage
{
    return {
        user,
        contents,
        channel,
        isBot,
        emotes: state.emotes || {},
        when: new Date(parseInt(state['tmi-sent-ts'])),
        isFirst: !!state['first-msg'],
    };
}

/**
 * @param {ChatEventHandler} handler
 *
 * @return {ChatMessage}
 */
export function ChatAddEventHandler(
    handler: ChatEventHandler): void
{
    _events.push(handler);
}

/**
 * @param {ChatMessage} message
 *
 * @return {void}
 */
export function ChatDispatch(
    message: ChatMessage): void
{
    for (const event of _events) {
        event(message);
    }
}
