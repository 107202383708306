export const JukeboxConfig = {
    uri: {
        prefix: '/sounds',
    },
    songs: {
        shuffle: {
            attempts: 10,
        },
        history: {
            count: 25,
        },
    },
};
