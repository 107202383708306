/**
 * Relative Imports
*/

import { ConfigKey } from './types';

/**
 * Locals
*/

const config: { [P in ConfigKey]?: string } = {};

/**
 * Public Functions
*/

/**
 * Retrieves the config value stored in the specified CSS psuedo-element.
 *
 * @param {ConfigKey} key
 *
 * @return {string}
 */
export function Config(
    key: ConfigKey,
    defaultValue: string = null): string
{
    const params = new URLSearchParams(location.search);

    if (params.get(key)) {
        return params.get(key);
    }

    if (key in config) {
        return config[key];
    }

    if (!document.getElementById(key)) {
        const element = document.createElement('div');

        element.id = key;
        element.style.display = 'none';

        document.body.appendChild(element);
    }

    const style = window.getComputedStyle(document.getElementById(key), '::before');
    const content = style.content;

    switch (content) {
    case 'none':
        return defaultValue;
    default:
        config[key] = content.slice(1, -1); // Remove quotation marks added by CSS.
    }

    return config[key];
}

/**
 * Waits for the required config keys to be injected into CSS, presumably by OBS.
 * If the keys are not found within 10 seconds then the promise will be rejected.
 *
 * @return {Promise<void>}
 */
export function ConfigInit(): Promise<void>
{
    return new Promise((resolve, reject) => {
        let attemps = 0;

        const required: Array<ConfigKey> = ['channel', 'oauth', 'username'];
        const wait = (): void | NodeJS.Timeout => {
            if (++attemps > 100) {
                return reject();
            }

            for (const key of required) {
                if (!Config(key)) {
                    return setTimeout(wait, 100);
                }
            }

            resolve();
        };

        wait();
    });
}
